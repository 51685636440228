<template>
  <v-card :dark="$dark.get()" height="92.1vh" style="overflow-y: auto">
    <v-card-title>{{ $route.name }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col :cols="checkType.first">
          <input ref="imgInput" type="file" hidden @change="setImage($event)" />
          <v-img
            v-ripple
            contain
            :style="`height: ${checkType.height}`"
            :src="preview || 'https://i.stack.imgur.com/y9DpT.jpg'"
            @click="$refs.imgInput.click()"
          ></v-img>
        </v-col>
        <v-col :cols="checkType.second">
          <v-form ref="formInput">
            <v-select
              v-model="fileInput.type"
              :items="types"
              label="Тип"
              item-text="name"
              item-value="key"
              :rules="[$validate.required]"
            ></v-select>
            <v-file-input
              v-model="fileInput.pdf"
              label="PDF"
              prepend-icon=""
              accept=".pdf"
            ></v-file-input>
            <v-text-field
              v-model="input.name"
              label="Название"
              :rules="[$validate.required]"
            ></v-text-field>
            <v-text-field
              v-model="input.additional"
              label="Дополнительно"
            ></v-text-field>
            <v-select
              v-model="input.departmentsId"
              label="Филиалы"
              multiple
              :items="USER.userInfo.departments"
              item-value="id"
              item-text="name"
              :rules="[$validate.required]"
            ></v-select>
            <v-row>
              <v-col cols="6">
                <v-menu
                  ref="menu"
                  v-model="menu1"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  :dark="$dark.get()"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Начало"
                      :value="input.dateStart"
                      readonly
                      v-bind="attrs"
                      clearable
                      :rules="[$validate.required]"
                      @click:clear="input.dateStart = null"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="input.dateStart"
                    no-title
                    :max="input.dateEnd"
                    @change="menu1 = !menu1"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  :dark="$dark.get()"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Конец"
                      :value="input.dateEnd"
                      readonly
                      v-bind="attrs"
                      clearable
                      :rules="[$validate.required]"
                      @click:clear="input.dateEnd = null"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="input.dateEnd"
                    no-title
                    :max="input.startDate"
                    @change="menu2 = !menu2"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-btn v-if="!edit" class="mt-5" color="success" @click="addBanner">
              Добавить
            </v-btn>
            <v-btn v-else class="mt-5" color="success" @click="editBanner">
              Сохранить
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import banner from "../model/banner";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      fileInput: {
        type: "desktopimg",
        file: null,
        pdf: null,
        id: null,
      },
      input: {
        additional: "",
        dateEnd: "",
        dateStart: "",
        departmentsId: [],
        name: "",
        position: null,
      },
      types: [
        {
          id: 1,
          name: "Для телефона",
          key: "mobileimg",
        },
        {
          id: 2,
          name: "Для компьютеров",
          key: "desktopimg",
        },
      ],
      preview: null,
      menu2: false,
      menu1: false,
      edit: false,
    };
  },
  computed: {
    ...mapGetters({
      USER: "User/STATE",
    }),
    checkType() {
      if (this.fileInput.type === "desktopimg") {
        return { first: 12, second: 12, height: "500px" };
      } else {
        return { first: 5, second: 7, height: "83.8vh" };
      }
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      this.edit = true;
      let response = await this.getBanner(this.$route.params.id);
      this.input = response.data;
      this.input.departmentsId = this.input.departments;
    }
  },
  methods: {
    ...mapActions({
      add: "Banner/ADD_BANNER",
      edit: "Banner/EDIT_BANNER",
      addFile: "Banner/ADD_FILE",
      getBanner: "Banner/GET_BANNER",
    }),
    setImage(val) {
      this.fileInput.file = val.target.files[0];
      this.preview = URL.createObjectURL(this.fileInput.file);
    },
    async addBanner() {
      if (this.$refs.formInput.validate()) {
        this.input.dateEnd = this.input.dateEnd.substr(0, 10) + "T00:00:00";
        this.input.dateStart = this.input.dateStart.substr(0, 10) + "T00:00:00";
        let response = await this.add(this.input);
        if (response.type === "success") {
          if (this.fileInput.file || this.fileInput.pdf) {
            this.fileInput.id = response.data.id;
            await this.addFile(this.fileInput);
          }
          this.$router.push("/banners");
        } else {
          this.$notify({
            group: "app",
            type: "error",
            title: "Ошибка",
            text: response.text,
          });
        }
      }
    },
    async editBanner() {
      if (this.$refs.formInput.validate()) {
        let model = new banner();
        this.input.dateEnd = this.input.dateEnd.substr(0, 10) + "T00:00:00";
        this.input.dateStart = this.input.dateStart.substr(0, 10) + "T00:00:00";
        const input = model.setInput(this.input);
        let response = await this.add(this.input);
        if (response.type === "success") {
          if (this.fileInput.file || this.fileInput.pdf) {
            this.fileInput.id = input.id;
            await this.addFile(this.fileInput);
          }
          this.$router.push("/banners");
        } else {
          this.$notify({
            group: "app",
            type: "error",
            title: "Ошибка",
            text: response.text,
          });
        }
      }
    },
  },
};
</script>
