export default class test {
  constructor() {
    this.input = {};
  }
  setInput(data) {
    return {
      id: data.id,
      name: data.name,
      position: data.position,
      departmentId: data.departmentId,
    };
  }
}
